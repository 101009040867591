<template>
  <div>
    <ProjectSummary
      v-if="inline"
      :project-exid="projectExid"
      :inline="inline"
    />
    <v-dialog
      v-else
      v-model="dialog"
      eager
      fullscreen
      hide-overlay
      scrollable
      persistent
      transition="fade-transition"
      :content-class="`project-details ${projectScreenSize}`"
      no-click-animation
      @keydown.esc="dialog = false"
    >
      <ProjectSummary
        v-if="dialog"
        :project-exid="projectExid"
        class="background-white"
        @close-dialog="dialog = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import ProjectSummary from "@/components/projects/ProjectSummary"
import { mapStores } from "pinia"
import { useLayoutStore } from "@/stores/layout"

export default {
  components: {
    ProjectSummary,
  },
  props: {
    projectExid: {
      type: String,
      required: true,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    showDialog: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapStores(useLayoutStore),
    dialog: {
      get() {
        return this.showDialog
      },
      set() {
        this.$emit("close-dialog")
      },
    },
    projectScreenSize() {
      if (this.layoutStore.isMiniSidebar) {
        return "project-details--fullscreen"
      }

      return ""
    },
  },
  created() {
    this.$root.$on("sidebar-navigation", () => {
      this.dialog = false
    })
  },
  mounted() {
    document.documentElement.style.setProperty(
      "overflow",
      "hidden",
      "important"
    )
  },
  beforeDestroy() {
    document.documentElement.style.removeProperty("overflow")
  },
}
</script>

<style scoped>
.background-white {
  background-color: white;
}
</style>
