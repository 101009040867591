<template>
  <v-dialog v-model="dialog" max-width="550px" persistent @keydown.esc="close">
    <v-card>
      <v-card-title>
        <span>{{ formTitle }}</span>
      </v-card-title>
      <v-divider />
      <ValidationObserver v-slot="{ invalid, passes }" ref="addRouter">
        <v-card-text class="pt-5">
          <v-form>
            <ERow>
              <ECol v-if="hideCheckbox" cols="12" md="6" class="mt-n7 mb-5">
                <v-checkbox
                  v-model="showCloneRouter"
                  class="resize-checkbox"
                  hide-details
                  @change="toggleCloneRouterCheckbox"
                >
                  <template #label>
                    <div class="caption">
                      Copy settings from existing router
                    </div>
                  </template>
                </v-checkbox>
              </ECol>
              <ECol v-if="hideCheckbox" cols="12" md="6" class="mt-n2 py-0">
                <Autocomplete
                  v-if="showCloneRouter"
                  v-model="selectedRouter"
                  item-value="id"
                  item-text="serialNumber"
                  label="Serial Number"
                  attach
                  clearable
                  no-filter
                  :hide-details="false"
                  :cache-items="false"
                  :provider="getRouters"
                  :provider-params="routerParams"
                  @change="onChangeRouter"
                />
              </ECol>
              <ECol cols="12" sm="12" md="12" lg="12" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Serial Number"
                  rules="required"
                >
                  <v-text-field
                    v-model="editedItem.serialNumber"
                    dense
                    outlined
                    :error-messages="errors"
                    label="Serial Number"
                    placeholder="110xxxxxx"
                  />
                </ValidationProvider>
              </ECol>
              <ECol cols="12" sm="12" md="12" lg="12" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="VPN Server"
                  rules="required"
                >
                  <v-select
                    v-model="editedItem.vpnServer"
                    :items="vpnServers"
                    label="VPN Server"
                    dense
                    outlined
                    :error-messages="errors"
                    item-value="value"
                    item-text="name"
                  />
                </ValidationProvider>
              </ECol>
              <ECol cols="12" sm="12" md="6" lg="6" class="py-0">
                <v-text-field
                  v-model="editedItem.vpnUserId"
                  dense
                  outlined
                  label="VPN User ID"
                />
              </ECol>
              <ECol cols="12" sm="12" md="6" lg="6" class="py-0">
                <v-text-field
                  v-model="editedItem.vpnPassword"
                  dense
                  outlined
                  label="VPN Password"
                />
              </ECol>
              <ECol cols="12" sm="12" md="12" lg="12" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Router Type"
                  rules="required"
                >
                  <v-combobox
                    v-model="editedItem.routerType"
                    :items="routerTypes"
                    label="Router Type"
                    persistent-hint
                    dense
                    outlined
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </ECol>
              <ECol cols="12" sm="12" md="6" lg="6" class="py-0">
                <v-text-field
                  v-model="editedItem.routerUserId"
                  dense
                  outlined
                  label="Router User ID"
                />
              </ECol>
              <ECol cols="12" sm="12" md="6" lg="6" class="py-0">
                <v-text-field
                  v-model="editedItem.routerPassword"
                  dense
                  outlined
                  label="Router Password"
                />
              </ECol>
              <ECol cols="12" sm="12" md="12" lg="12" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Router HTTP Port"
                  rules="required"
                >
                  <v-text-field
                    v-model="editedItem.routerHttpPort"
                    :error-messages="errors"
                    dense
                    outlined
                    label="Router HTTP Port"
                  />
                </ValidationProvider>
              </ECol>
              <ECol cols="12" sm="12" md="6" lg="6" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Power Type"
                  rules="required"
                >
                  <v-select
                    v-model="editedItem.powerType"
                    :items="powerTypes"
                    label="Power Type"
                    dense
                    outlined
                    :error-messages="errors"
                    item-value="value"
                    item-text="name"
                  />
                </ValidationProvider>
              </ECol>
              <ECol cols="12" sm="12" md="6" lg="6" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Power Schedule"
                  rules="required"
                >
                  <v-select
                    v-model="editedItem.powerSchedule"
                    :items="powerSchedules"
                    label="Power Schedule"
                    dense
                    outlined
                    :error-messages="errors"
                    item-value="value"
                    item-text="name"
                  />
                </ValidationProvider>
              </ECol>
              <ECol cols="12" sm="12" md="12" lg="12" class="py-0">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Status"
                  rules="required"
                >
                  <v-select
                    v-model="editedItem.status"
                    :items="routerStatus"
                    label="Router Status"
                    dense
                    outlined
                    :error-messages="errors"
                    item-value="value"
                    item-text="name"
                  />
                </ValidationProvider>
              </ECol>
              <ECol cols="12" sm="12" md="12" lg="12" class="py-0">
                <Autocomplete
                  v-model="editedItem.cameras"
                  item-value="id"
                  item-text="name"
                  label="Cameras"
                  multiple
                  chips
                  small-chips
                  append-icon="mdi-menu-down"
                  deletable-chips
                  :list-items="cameras"
                  resource="cameras"
                />
              </ECol>
              <ECol cols="12" sm="12" md="12" lg="12" class="py-0">
                <ERow no-gutters>
                  <ECol cols="12" sm="12" md="7" lg="7">
                    <Autocomplete
                      v-model="editedItem.sims"
                      item-value="id"
                      item-text="number"
                      label="SIMs"
                      multiple
                      chips
                      small-chips
                      deletable-chips
                      append-icon="mdi-menu-down"
                      :list-items="sims"
                      resource="sims"
                      :search="simSearch"
                      @on-search-item="initSelectedSim"
                    />
                  </ECol>
                  <ECol cols="12" sm="12" md="5" lg="5" class="px-1 mt-3">
                    <span class="caption">Or</span>
                    <SimsDialog
                      for-form-component
                      @reload-sims="(sim) => (simSearch = sim)"
                    />
                  </ECol>
                </ERow>
              </ECol>
            </ERow>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close"> Cancel </v-btn>
          <v-btn color="primary" text :disabled="invalid" @click="passes(save)">
            Save
          </v-btn>
        </v-card-actions>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { mapStores } from "pinia"
import { useReportStore } from "@/stores/report"

import {
  VPN_SERVERS,
  POWER_SCHEDULES,
  POWER_TYPES,
  ROUTER_STATUSES,
} from "@/components/constants.js"
import Autocomplete from "@evercam/shared/components/Autocomplete"
import SimsDialog from "@/components/sims/SimsDialog"
import Vue, { PropType } from "vue"
import { PowerType, Router, RouterStatus } from "@evercam/shared/types/router"
import { AdminApi } from "@evercam/shared/api/adminApi"

export default Vue.extend({
  name: "RouterDialog",
  components: {
    Autocomplete,
    SimsDialog,
  },
  props: {
    editedRouter: {
      type: Object as PropType<Router>,
      default: () => ({} as Router),
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      routerTypes: [],
      editedIndex: -1,
      vpnServers: VPN_SERVERS,
      routerStatus: ROUTER_STATUSES,
      powerSchedules: POWER_SCHEDULES,
      powerTypes: POWER_TYPES,
      editedItem: {
        serialNumber: null,
        vpnUserId: "",
        vpnPassword: "",
        routerType: "",
        routerUserId: "",
        routerPassword: "",
        routerHttpPort: null,
        powerType: "" as PowerType,
        powerSchedule: "",
        vpnServer: "",
        status: "" as RouterStatus,
        cameras: [],
        sims: [],
      } as Router,
      defaultItem: {
        serialNumber: null,
        vpnUserId: "",
        vpnPassword: "",
        routerType: "",
        routerUserId: "",
        routerPassword: "",
        routerHttpPort: null,
        powerType: "" as PowerType,
        powerSchedule: "",
        vpnServer: "",
        status: "" as RouterStatus,
        cameras: [],
        sims: [],
      } as Router,
      cameras: [],
      sims: [],
      dialog: false,
      simSearch: "",
      selectedRouter: null,
      showCloneRouter: false,
      updateRouter: false,
    }
  },
  computed: {
    ...mapStores(useReportStore),
    hideCheckbox() {
      return !this.editedRouter?.id
    },
    isNewRouter() {
      return !this.editedRouter?.id && !this.updateRouter
    },
    formTitle() {
      return this.isNewRouter ? "Add Router" : "Edit Router"
    },
    getRouters() {
      return AdminApi.routers.getRouters
    },
  },
  watch: {
    editedRouter: {
      immediate: true,
      deep: true,
      handler() {
        this.initForm(this.editedRouter)
      },
    },
    "$attrs.value": {
      immediate: true,
      handler(value) {
        this.dialog = value
      },
    },
    "editedItem.serialNumber": {
      immediate: true,
      handler(value) {
        if (value && this.selectedRouter) {
          this.updateRouter = value == this.selectedRouter?.serialNumber
        }
      },
    },
    dialog(val) {
      if (val) {
        this.initForm(this.editedRouter)
      }
      this.$emit("input", val)
    },
  },
  mounted() {
    this.getRouterTypes()
  },
  methods: {
    toggleCloneRouterCheckbox(value) {
      if (!value) {
        this.clear()
      } else {
        if (this.selectedRouter) {
          this.selectedRouter.cameras = [
            ...(this.selectedRouter.cameras || []),
            ...(this.editedRouter.cameras || []),
          ]
          this.initForm(this.selectedRouter)
        }
      }
    },
    onChangeRouter(router) {
      if (router) {
        this.updateRouter = true
        router.cameras = [
          ...(router.cameras || []),
          ...(this.editedRouter.cameras || []),
        ]
        this.initForm(router)
      }
    },
    routerParams(val) {
      return {
        sort: "created_at|asc",
        limit: 50,
        page: 1,
        serialNumber: val,
      }
    },
    async getRouterTypes() {
      try {
        this.routerTypes = await AdminApi.routers.getRouterTypes()
      } catch (error) {
        this.$notifications.error({
          text: "Could not load router types!",
          error,
        })
      }
    },
    async save() {
      if (this.isNewRouter) {
        this.doCreateRouter()
      } else {
        this.doUpdateRouter()
      }
    },
    async doCreateRouter() {
      this.reportStore.loading = true
      await AdminApi.routers
        .createRouter(this.editedItem)
        .then((res) => {
          this.editedItem.id = res.routerId
          this.reportStore.items = [...this.reportStore.items, this.editedItem]
          this.$notifications.success("Router has been added.")
          this.close()
          this.$emit("router-created", this.editedItem)
        })
        .catch((error) => {
          this.$notifications.error({ text: "Failed to create router!", error })
        })
        .finally(() => {
          this.reportStore.loading = false
        })
    },
    async doUpdateRouter() {
      this.reportStore.loading = true
      await AdminApi.routers
        .updateRouter(this.editedItem.id, this.editedItem)
        .then(async () => {
          this.$notifications.success("Router has been updated.")
          if (!this.inline) {
            const router =
              (await AdminApi.routers.getRouter(this.editedItem?.id)) || {}
            this.reportStore.items = this.reportStore.items.map((item, index) =>
              index === this.editedIndex
                ? { ...this.editedItem, ...router }
                : item
            )
          }
          this.$emit("change", this.editedItem)
          this.close()
        })
        .catch((error) => {
          this.$notifications.error({ text: "Failed to update router!", error })
        })
        .finally(() => {
          this.reportStore.loading = false
        })
    },
    initForm(router: Router) {
      if (router.cameras) {
        this.cameras = router.cameras
      } else {
        this.cameras = []
      }
      if (router.sims) {
        this.sims = router.sims
      } else {
        this.sims = []
      }
      this.editedIndex = this.reportStore.items.indexOf(router)
      this.editedItem = Object.assign({}, router)
    },
    async editItem(router: Router) {
      this.initForm(router)
      this.dialog = true
    },
    close() {
      this.dialog = false
      this.clear()
    },
    clear() {
      this.$refs.addRouter.reset()
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },
    initSelectedSim(items) {
      if (!this.simSearch) {
        return
      }
      this.editedItem.sims = [
        ...(this.editedItem.sims || []),
        items.find((value) => value.number === this.simSearch),
      ]
      this.sims = this.editedItem.sims
    },
  },
})
</script>
